'use client';

import React, { useEffect, useRef, useState } from "react";
import ClientComponent from "./client_component";
import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Project, actions as builderActions } from "@/store/builderSlice";
import BuilderSelct from "@/store/builderStoreSelector";
import { BUILDER_IN_PROGRESS } from "@/utils/builder/builderConstants";
import { saveProject as saveProjectThunk } from "@/store/builder/thunks/projectSaveThunk";
import { BUILDER_SUCCESS } from '@/utils/builder/builderConstants';
import cssStyles from './builder.module.scss';
import { gtmClickTracking, navigateWithHistory } from "@/utils";
import { ProjectProduct } from "@/store/projects";
import router, { useRouter } from "next/router";

const BuyNowComponent = () => {
    const router = useRouter()

    const dispatch = useDispatch();
    const project: Project = BuilderSelct.project();
    const saveStatus: string = BuilderSelct.ui().saveStatus;
    const urlParams: { [key: string]: string } = BuilderSelct.ui().urlParams;
    const isShowCaseFlow = urlParams.showcase === 'true';
    const buyNowRef = useRef(false);
    const [addToCartClicked, setAddToCartClicked] = useState(false);


    const saveAndNavigateToProjectPage = () => {
        setAddToCartClicked(true);

        window?.nestDataLayer?.push({ 
            "event": "project_completed",
            "pageType": window.pageViewDataLayerObj.pageType,
            "pageName": window.pageViewDataLayerObj.pageName,
            "pagePath": window.pageViewDataLayerObj.pagePath,
            "projectId": project.projectId
        });

        buyNowRef.current = true;
        dispatch(builderActions.updateProjectStatus({ status: 'ready_to_buy' }));
        dispatch(builderActions.updateSaveStatus({ saveStatus: BUILDER_IN_PROGRESS }));
        dispatch(saveProjectThunk({}) as any);
    }

    if (buyNowRef.current && saveStatus === BUILDER_SUCCESS) {
        buyNowRef.current = false;
        const origin =
            typeof window !== 'undefined' && window.location.origin
                ? window.location.origin
                : '';
        let projectUrl = `${origin}/project?projectId=${project.projectId}`;
        if (isShowCaseFlow) {
            projectUrl += '&showcase=true&auth=false'
        } else {
            projectUrl += '&showcase=true';
        }

        window.location.href = projectUrl;
        
    }

    // const handleAddAllToCartOrCheckout = async (e:any, isShowCase?: boolean, prodz?: any) => {
    //     //setAddingToCart(true);
    //     if (!isShowCase && products.length == projectCartItems.length+unavailableItems.length) {
    //         gtmClickTracking({event: 'Project_details_add_all_to_cart_clicked', ecommerce: {currency: 'USD', value: 0, items: []}});
    //         setTimeout(() => {
    //           navigateWithHistory(router, checkoutState?.webUrl);
    //         }, 5000);
    //     } else {
    //         const prods = (isShowCase) ? prodz : project.products.filter((prod:ProjectProduct) => isPurchasable(prod)==true);
    //         const totalValue = prods.reduce((acc: number, prod: { price: any; }) => acc + prod.price, 0);
    //         const itemObjs = prods.map((prod: ProjectProduct) => (
    //           {
    //             item_id: prod.productId,
    //             item_name: prod.productName,
    //             price: prod.price,
    //             quantity: 1
    //           }
    //         ));
    //         gtmClickTracking({event: 'Project_details_add_all_to_cart_clicked', ecommerce: {currency: 'USD', value: totalValue, items: itemObjs}});
    //         //setProductsInFlight(prods.map((prod: ProjectProduct) => prod.productId));
    //         await addToCart( prods );
    //         setTimeout(() => {
    //           navigateWithHistory(router, '/cart');
    //         }, 5000);
    //     }
    //     //setProductsInFlight([]);
    //     //setAddingToCart(false);
    //   };
    
    //   async function addToCart(prods: any) {
    //       console.log('--------project_detail addToCart--------', 'prods: ', prods);
    //       if (!prods) return;
    //       let lineItemsToAdd = [] as any;
    //       prods.forEach( (product: any) => {
    //         const variantId = product.variantId;
    //         const quantity = 1;
    //         lineItemsToAdd.push({
    //           variantId,
    //           quantity,
    //         });
    //       });
    //       console.log('--------addToCart--------', 'lineItemsToAdd: ', lineItemsToAdd);
    //       try {
    //           const response = await addVariant(checkoutID, lineItemsToAdd);
    //           console.log('-------addToCart------', 'response: ', response);
    //           setProjectCartItems([...projectCartItems, ...prods.map( (product:ProjectProduct) => product.productId)])
    //       } catch(err) {
    //           console.error('-------addToCart------', 'error adding product(s) to cart: ', err);
    //       }
    //       console.log('--------addToCart--------', 'projectCartItems: ' ,projectCartItems);
    //   }

    return <Grid item>
        <Button
            variant="contained"
            onClick={saveAndNavigateToProjectPage}
            size="small"
            disabled={project.products.length < 1}
            className={cssStyles["next-button"]}
        >
            {addToCartClicked ? "Adding to Cart..." : "Add to Cart"}
        </Button>
    </Grid >
}

export default ClientComponent(BuyNowComponent);