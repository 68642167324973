import React, { useEffect, useRef} from "react";
import Carousel from 'react-multi-carousel';
import { Box, Button, Card, CardMedia, Paper } from '@mui/material'
import  Link from "next/link";
import { ProductData } from "../../translator";
import styles from './designs_slideshow.module.scss'
import "react-multi-carousel/lib/styles.css";
import Image from 'next/image';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
export interface PanelItem {
    id : string;
    name?: string;
    image: string;
    subPanel? : Panel;
    selected? : boolean;
    displayName?: string;
}

export interface Panel {
    name : string;
    panelItems: PanelItem[];
    slides: Slide[];
}

export interface Slide {
    id: string;
    name? : string;
    displayName?: string;
    image : string;
    link? : string;
    selected?: boolean;
    products?: ProductData[];
    caption?: string;
}

export interface CarouselData {
    panelData: Panel;
    dispatcher?: any;
}
export enum Selection{
    LeftPanel,
    RightPanel,
    Slide
}
export interface CurrentSelection {
    leftPanelId? : string;
    leftPanelIndex : number;
    rightPanelId? : string;
    rightPanelIndex : number;
    slideId? : string;
    slideIndex : number;
    selection?: Selection;
}


export interface DesignerSlideShowData {
    designs: Slide[];
    dispatcher: any;
    height?: any;
}

const DesignsSlideshowComponent: React.FC<DesignerSlideShowData> = (designerSlideShowData: DesignerSlideShowData) => {

    const {designs, dispatcher, height} = designerSlideShowData;
//console.log("------- DesignsSlideshowComponent : start------")

    const responsiveData = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 0 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 767, min: 601 } ,
            slidesToSlide: 1,
            items: 1
        },
        mobile: {
            breakpoint: { max: 600, min: 320 } ,
            slidesToSlide: 1,
            items: 1
        }
    };
    const myRefs= useRef<HTMLAnchorElement[]>([]);

    const carouselRef = useRef<any>();

    useEffect(() => {
        if(carouselRef.current) {
            carouselRef.current.goToSlide(0)
        }
    },[designs[0]?.id])


    const handleSlideSelection = (designIndex: any, event?: any) => {
        //dispatch(actions.designSelected({designId : designs[designIndex].id, designProducts : designs[designIndex].products,  designIndex : designIndex}));
        if( typeof dispatcher == "function") {
            designs[designIndex] && dispatcher({designId : designs[designIndex].id, designProducts : designs[designIndex].products,  designIndex : designIndex})
        }
    }

    const generateSlides = () => {
        if (designs) {
            return designs.map((eachSlide: any,i: number)=>{
                return (<Paper key={`${eachSlide.id}_${eachSlide.name}`} square>
                        <div className={`${styles["sub-page-hero-e-image"]} ${styles.img}`} data-index={i}>
                            {eachSlide.caption && (<div className={styles["slide-caption"]}>{eachSlide.caption}</div>)}
                            {/* {<span style={{backgroundImage: `url(${eachSlide.image})`,backgroundSize:"cover",backgroundPosition:"50% 50%",display:"inline-block"}}></span> } */}
                            <span className={styles["slide-wrapper"]}>
                                <Image 
                                    src={eachSlide.image || "/images/broken_image.png"}
                                    alt={eachSlide.name || eachSlide.image || '...'}
                                    fill={true}
                                    style={{
                                        objectFit: "contain", // Or "cover", depending on your preference
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    onError={(e) => console.error((e.target as HTMLImageElement).src + " failed to load")}
                                />
                            </span>
                        </div>
                        
                        { eachSlide.link && 
                        <Link href={eachSlide.link}>
                            <div className={styles["viewProductDetailsWrapper"]}>  
                                <Box component="div" className={styles["viewProductDetails"]} sx={{backgroundColor: 'buttons_bg.primary' }}>
                                        <span> {eachSlide.name} <ChevronRightIcon className={styles["icon"]} /> </span>
                                        
                                </Box>
                            </div>
                        </Link>

                        }

                    </Paper>)
            })
        } else {
            return (<Paper key="loading">
                            <p>Loading...</p>
                    </Paper>)
        }
    }

    const generateCarousal = () => {
        return (
            <Carousel afterChange={(previousSlide, {currentSlide}) => {
                handleSlideSelection(currentSlide);
              }} 
              ref={carouselRef}
              partialVisible={true}
              ssr={true}
              showDots={true}
              dotListClass="react-multi-carousel-dot-list" // Use consistent naming 
              autoPlay={false} 
              responsive={responsiveData} 
              removeArrowOnDeviceType={["tablet", "mobile"]}
              keyBoardControl={true}
              containerClass="carousel-container"
            //   dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              //deviceType={this.props.deviceType}
               >
                {designs ? generateSlides() : <div className="load-designs">Loading designs....</div>}
            </Carousel>)
    }
    
    return (
        
        <div className={styles["design-slideshow-panel"]}>
            {generateCarousal()}  
        </div>);

}
export default DesignsSlideshowComponent;