import React, { useState, useEffect, useRef } from 'react'
import {
	Box,
	TextField,
	InputAdornment,
	IconButton,
	Paper,
	List,
	ListItem,
	ListItemText,
	Typography,
	Divider,
	useMediaQuery
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useRouter } from "next/router";
import { gtmClickTracking } from '@/utils';
const SearchBar = (props:any) => {
	const {setSearchBarOpen,query,setQuery }=props
	const router = useRouter()
	// const [query, setQuery] = useState('')
	const [searchQuery, setSearchQuery] = useState('')
	const [showSuggestions, setShowSuggestions] = useState(false)
	const [productSuggestions, setProductSuggestions] = useState([])
	const [searchSuggestions, setSearchSuggestions] = useState([])
	const [showNoResult, setShowNoResults] = useState(false)
	const dropDownRef = useRef<any>(null)
	const inputRef = useRef<any>(null)
    const isMobile = useMediaQuery("(max-width:600px)");

	// console.log(productSuggestions, 'product suggestions----------------------')
	// console.log(searchSuggestions)
	useEffect(() => {
		function handler(event: any) {
			if (dropDownRef?.current && !dropDownRef?.current.contains(event.target))
				setShowSuggestions(false);
		}
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, []);
	useEffect(() => {
		function handler() {
			setShowSuggestions(false);
			if (inputRef.current) {
				inputRef.current.blur()
			}
		}
		window.addEventListener('scroll', handler)
		return () => {
			window.removeEventListener('scroll', handler)
		}
	}, [])
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setSearchQuery(query)
		}, 400)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [query])

	useEffect(() => {
		async function fetchData() {
			setShowNoResults(false)
			if (searchQuery.length > 0) {
				try {
					const response = await fetch(
						`https://d221a61rb87vel.cloudfront.net/v1/api/search?searchInput=${searchQuery}&autoComplete=true`
					)
					if (response.status == 200) {
						const data = await response.json()
						setShowNoResults(data?.productSuggestions.length === 0 && data?.searchSuggestions.length === 0)
						setProductSuggestions(data?.productSuggestions)
						setSearchSuggestions(data?.searchSuggestions)
					}
				} catch (error) {
					console.log(error, 'error at search bar')
					// setProductSuggestions([])
				}
			}
		}
		fetchData()
	}, [searchQuery])

	const handleInputChange = (event: any) => {
		event.preventDefault()
		setQuery(event.target.value)
		setShowSuggestions(event.target.value.length > 0)
	}
	const handleClear = () => {
		setQuery('')
		setShowSuggestions(false)
		setProductSuggestions([])
		setSearchSuggestions([])
	}

	function handleKeyDown(event: any) {
		if (event.key === 'Enter' && query.length > 0) {
			setShowSuggestions(false)

			// router.push(`/products/${query}`)
			// router.push(`/search?keyword=${encodeURIComponent(query)}`);
			// console.log('query---------------------------', encodeURIComponent(query))
			// const filteredSearchTerm = query.replace(' ', '').toLowerCase()

			// router.push(`/search/${encodeURIComponent(query)}`)
			
			const url = `/search/${encodeURIComponent(query)}`
			window.location.href = url
		}

	}
	useEffect(() => {
		const handleRouteChangeComplete = () => {
			setSearchBarOpen(false)
		};
	
		router.events.on('routeChangeComplete', handleRouteChangeComplete);
		return () => {
		  router.events.off('routeChangeComplete', handleRouteChangeComplete);
		};
	  }, [router.events]);
	const handleSearch = () => {
		if (query.length === 0) return
		setShowSuggestions(false)
		// router.push(`/search?keyword=${encodeURIComponent(query)}`);

		// router.push(`/products/${query}`)
		// const filteredSearchTerm=query.replace(' ','').toLowerCase()

		// router.push(`/search/${encodeURIComponent(query)}`)
		const url = `/search/${encodeURIComponent(query)}`
		window.location.href = url

	}
	function handleOnClickSearchSuggestion(event: any, searchTerm: string) {
		event.preventDefault()
		gtmClickTracking({
			event:'search_suggestion_selected',
			selectedSearchTerm:searchTerm
		})
		setShowSuggestions(false)
		setQuery(searchTerm)

		// const filteredSearchTerm = searchTerm.replace(' ', '').toLowerCase()
		// router.push(`/products/${filteredSearchTerm}`)
		// router.push(`/search?keyword=${encodeURIComponent(searchTerm)}`);
		// router.push(`/search/${encodeURIComponent(searchTerm)}`)
		const url = `/search/${encodeURIComponent(searchTerm)}`
		window.location.href = url

	}
	function handleOnClickProductSuggestion(event: any, url: string, title: string) {
		event.preventDefault()
		gtmClickTracking({
			event:'product_suggestion_selected',
			selectedProduct:title
		})
		setShowSuggestions(false)
		if (url) {
			setQuery(title)
			// router.push(url)
			window.location.href=url
		}
	}
	function handleInputFocus() {
		if (productSuggestions.length > 0 || searchSuggestions.length > 0) setShowSuggestions(true)
	}
	useEffect(() => {
		if (query.length === 0) {
			setProductSuggestions([])
			setSearchSuggestions([])
		}

	}, [query])

	return (
		<Box
			component='div'
			className='header-product-search'
			sx={{
				width: '100%',
				height:isMobile?'88px': '81px',
				backgroundColor: '#fef4ec',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'relative'
			}}
		>
			{/* Close Button */}
			{/* <IconButton
				onClick={() =>{ 
					setSearchBarOpen(false)
				gtmClickTracking({
					event:'search_bar_close'
				})
				}}
				sx={{
					position: 'absolute',
					top: '5px',
					right: '10px',
					color: 'gray',
					padding: 0,
				}}
			>
				<CloseIcon />
			</IconButton> */}
            <p style={{position: 'absolute',
					fontSize:'14px',
                    margin:0,
					top: '0',
					right: '10px',
					color: 'gray',
					padding: 0,
				    paddingTop:'2px',
					cursor:'pointer'
				}}    onClick={() =>{ 
						setSearchBarOpen(false)
					gtmClickTracking({
						event:'search_bar_close'
					})
					}}>Close</p>
			{/* Search Field */}
			<TextField
				ref={inputRef}
				autoComplete="off"
				variant="outlined"
				placeholder="What can we help you find?"
				value={query}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				onFocus={handleInputFocus}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{query && (
								<IconButton onClick={handleClear}>
									<CloseIcon sx={{ color: 'gray' }} />
								</IconButton>
							)}
							<IconButton onClick={handleSearch}>
								<SearchIcon sx={{ color: 'gray', width: '1.5em', height: '1.5em' }} />
							</IconButton>
						</InputAdornment>
					),
					sx: {
						fontFamily: 'Nunito, sans-serif',
						fontSize: '18px',
						backgroundColor: '#ffffff',
						borderRadius: '50px',
						height: '44px',
						maxWidth: {
							xs: '330px',
							sm: '500px',
							md: '550px'
						},
						width: '100%',
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: 'transparent'
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: 'transparent'
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: '#d9d9d9'
						},
						'& .MuiInputBase-input::placeholder': {
							color: '#b3b3b3'
						},
						'& .MuiInputBase-input': {
							color: '#000000'
						}
					}
				}}
				sx={{
					width: {
						xs: '330px',
						sm: '100%',
						md: '100%'
					},
					maxWidth: {
						xs: '330px',
						sm: '500px',
						md: '550px'
					}
				}}
			/>

			{/* Dropdown Suggestions */}
			{showSuggestions && (
				<Paper
					ref={dropDownRef}
					className='search-drop-down'
					sx={{
						position: 'absolute',
						top: '77%',
						left: '50%',
						transform: 'translateX(-50%)',
						width: {
							xs: '330px',
							sm: '500px',
							md: '550px'
						},
						maxHeight: 600,
						overflowY: 'auto',
						zIndex: 2000000
					}}
				>
					{showNoResult && <Typography
						variant="subtitle2"
						sx={{ padding: '8px 16px', fontWeight: 'bold', fontSize: '12px' }}
					>
						NO RESULTS FOUND
					</Typography>}
					{/* Search Suggestions Section */}
					{searchSuggestions.length > 0 && <List>
						<Typography
							variant="subtitle2"
							sx={{ padding: '8px 16px', fontWeight: 'bold', fontSize: '12px' }}
						>
							SEARCH SUGGESTIONS
						</Typography>
						<Divider sx={{ marginLeft: '16px', marginRight: '16px' }} />

						{searchSuggestions?.slice(0, 6)?.map((item: any, index) => (
							<ListItem key={index} button onClick={(event: any) => handleOnClickSearchSuggestion(event, item?.searchTerm)} sx={{ paddingTop: '2px', paddingBottom: '2px' }}>
								<ListItemText primary={item?.searchTerm} primaryTypographyProps={{ fontSize: '1.4rem' }} />
							</ListItem>
						))}
					</List>}

					{/* Product Suggestions Section */}
					{productSuggestions.length > 0 && <List sx={{ paddingTop: '4px' }}>
						<Typography
							variant="subtitle2"
							sx={{ padding: '0 16px 8px', fontWeight: 'bold', fontSize: '12px' }}
						>
							PRODUCT SUGGESTIONS
						</Typography>
						<Divider sx={{ marginLeft: '16px', marginRight: '16px' }} />

						{productSuggestions?.map((product: any) => (
							// <a href={product?.productPageUrl} key={product?.productSkuID}>
							<ListItem key={product?.productSkuID} button onClick={(event: any) => { handleOnClickProductSuggestion(event, product?.productPageUrl, product?.title) }} sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
								{product?.imageLink && <div style={{ marginRight: '5px' }}>
									<img
										src={product?.imageLink}
										alt={product?.title}
										width={50}
										height={50}
										style={{
											height: '50px',
											width: '50px'
										}}
									/>
								</div>}
								<ListItemText
									primary={product?.title}
									primaryTypographyProps={{
										variant: 'body2',
										noWrap: true
									}}
								/>
							</ListItem>
							// </a>
						))}
					</List>}
				</Paper>
			)}
		</Box>
	)
}

export default SearchBar