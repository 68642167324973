import React, { useEffect, useState} from 'react';
import { Link, IconButton, SwipeableDrawer, List, ListItem, ListItemButton } from "@mui/material";
import Badge from '@mui/material/Badge';
import LogoComponent from "../logo";
import styles from "./header.module.scss";
import { getLoggedInEmailAddress, gtmClickTracking, isCartPage, navigateWithHistory } from "@/utils";
import { signOutUser, getUserCartInfo } from "@/utils/useUserInfo";
import ContentSlider from "@/components/util/content_slider";
import { useRouter } from "next/router";
import Image from 'next/image';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import signInIcon from '../../assets/images/sign_in.svg'
import shoppingCartIcon from '../../assets/images/shopping_cart.svg';
import styleProfileIcon from '../../assets/images/loader.svg';
import myProjectsIcon from '../../assets/images/grid.svg';
import signoutIcon from '../../assets/images/signout.svg';
import profileUserIcon from '../../assets/images/user.svg';
import myRoomIcon from '../../assets/images/myrooms.svg';
import LinearProgress from '@mui/material/LinearProgress';
import searchOrangeIcon from '../../assets/images/UnionOrange.png'
import searchBlackIcon from '../../assets/images/Union.png'
import dynamic from 'next/dynamic';
import HeaderMenu from './menu/header_menu';
//import localMenuJson from './menu.json' assert { type: "json" };
import { getStaticBasics } from "@/utils/getStaticBasics";
import { transformTextToHtml } from "@/components/cms_renderer";


const HeaderComponent = (props) => {
    const { brand, headerType, globalData,loginEl, setLoginEl, headerMenuJson, headerTopCmsData, headerBottomCmsData,searchBarOpen,setSearchBarOpen } = props;
        
    const cmsBanner = headerTopCmsData?.items[0]?.content;
    const brandName = brand?.vendorName;
    const router = useRouter();
    const lsv = globalData?.lsv;
    const userInfo = globalData?.userInfo;
    const [cartCount, setCartCount] = useState(0);
    const [showProgress, setShowProgress] = useState(true);
    const [menuJson, setMenuJson] = useState(headerMenuJson);
    useEffect(() => {
        try {
            if (userInfo && userInfo?.status) {
                if (userInfo?.status === "DONE") {
                    setShowProgress(false);
                    getUserCartInfo(userInfo)?.then((cartData) => {
                        setCartCount(cartData?.cartCount || 0);
                        userInfo.crtD = cartData;
                        //setGobalData({ ...getGlobalData,  userInfo});
                    })
                } else {
                    setShowProgress(true);
                }
            }
        } catch (e) {
            console.log("Error in HeaderComponent getUserCartInfo useEffect", e);
        }
    }, [userInfo]);

    //const ClearCart = lazy(() => import('./clearCart'));

    const navigatorToLogin = () => {
        if (window.checkingForUserSession || getLoggedInEmailAddress()) {
            return;
        }
        window.location.href = `/login?next=${encodeURIComponent(window.location.href)}`
    }

    const navigateToOrderHistory = () => {
        navigateWithHistory(router, '/orderhistory');
    }

    const navigateToCart = () => {
        navigateWithHistory(router, '/cart');
    }

    const navigateToStyleProfile = () => {
        handleClose();
        navigateWithHistory(router, '/styleprofile');
    }

    const navigateToMyProjects = () => {
        handleClose();
        navigateWithHistory(router, '/projects');
    }

    const navigateToMyRoom = () => {
        handleClose();
        navigateWithHistory(router, '/myrooms');
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const LoginLayer = dynamic(() => import('@/components/login'), {
        ssr: false,
      });

    
    const openLoginLayer = Boolean(loginEl);
    const handleLoginClick = (event) => {
        setLoginEl(event.currentTarget);
    };
    const handleLoginClose = () => {
        setLoginEl(null);
    };
    const loginCallback = (callbackObj) => {
        console.log("loginCallback called", callbackObj);
        globalData?.loginCallback(callbackObj);
    };


    const [openDrawer, setOpenDrawer] = React.useState(false);
    const toggleDrawer = (open) => () => {
        setOpenDrawer(open)
    };
    const HeaderMarquee = ({ content, marqueeDuration = 0 , isImage = false}) => {
        content = "https://cms.nestingale.com/uploads/RN_1000_40_50_e69ca6e450.jpg";
        isImage = true;
        return (
            <div className={ marqueeDuration ? styles.marquee_content : styles.no_marquee_content}
                style={marqueeDuration ? {animationDuration: `${marqueeDuration}s`} : {}}>
            { isImage ? <Image src={content} alt="Marquee Image" layout="fill" objectFit="cover" /> : <span>{content}</span> }
            </div>
        );
      };
    function handleSearchIconClick(){
        setSearchBarOpen(true)
        gtmClickTracking({
            event:'search_bar_open'
        })
        if (typeof window !== "undefined") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const { createRoomPlan } = router.query;
    return (
        <>
            <div className={styles.headerSectionWrapper}>
            {cmsBanner && <div className={styles.promo_banner}>
                <span>{transformTextToHtml(cmsBanner)}</span>
            </div>}
            <div className={styles.headsection}>
            { Object.keys(menuJson).length > 0 &&
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    className={styles.hamberger_btn}
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
            }
                <div className={styles.logosection}>
                    <LogoComponent brand={brand} footer={false} />
                </div>
                {headerType === 'full' &&
                    <div className={styles.middle_section}>
                        <HeaderMenu menuJson={menuJson} deviceType="desktop" />
                    </div>
                }
                {
                    (showProgress) ? (
                        <>
                            <div className={styles.login__section} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <LinearProgress color="inherit" />
                            </div>
                        </>
                    ) : (

                        (lsv?.showCart && !createRoomPlan) &&
                        (<div className={styles.login__section}>
                            {
                                lsv?.emailAddress ?
                                    <>
                                       <img src={searchBarOpen?searchOrangeIcon.src:searchBlackIcon.src} alt="search" width={18} height={18} onClick={handleSearchIconClick} style={{cursor:'pointer'}}/>

                                        <span className={styles.sign_in_hide_mweb}>
                                            
                                            <Link className={styles.sign_in} onClick={handleClick}>
                                                <img className={styles.border} src={profileUserIcon.src} alt="profile" style={{ 'width': '24px', 'height': '24px' }} />
                                                <strong className={styles.ellipsis}>...</strong>
                                            </Link>
                                        </span>
                                    </> :
                                    <>
                                        {/*<Link className={styles.sign_in} onClick={navigatorToLogin}><img src={signInIcon.src} alt="Sign In" /><span>SIGN IN</span></Link> */}
                                        <span className={styles.sign_in} >
                                           <img src={searchBarOpen?searchOrangeIcon.src:searchBlackIcon.src} alt="search" width={18} height={18} onClick={handleSearchIconClick} style={{cursor:'pointer'}}/>

                                            <Link id="sign_in" className={styles.sign_in} onClick={handleLoginClick}>
                                                <img className={styles.border}  src={profileUserIcon.src} alt="Sign In" style={{ 'width': '24px', 'height': '24px' }} /><span>SIGN IN</span>
                                            </Link>
                                        </span>
                                    </>
                            }
                            {
                                (!isCartPage() &&
                                    (<Link className={styles.cart_link} onClick={navigateToCart} sx={{ color: 'primary.main', cursor: 'pointer' }}>
                                        <Badge id="header_cart_icon" badgeContent={cartCount} color="primary" sx={{ cursor: 'pointer' }}>
                                            <img src={shoppingCartIcon.src} style={{height: '19px'}} alt="Cart logged In" />
                                        </Badge>
                                    </Link>)
                                )
                            }
                        { lsv?.emailAddress ? 
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                className={styles.menu_list}
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        borderRadius: '12px',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 20,
                                            width: 15,
                                            height: 15,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        }
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={navigateToOrderHistory} style={{display: "flex", justifyContent: "center"}}>
                                    Order History
                                </MenuItem>
                                <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.30)' }} />
                                <MenuItem onClick={signOutUser}>
                                    <img src={signoutIcon.src} alt="Sign Out" />Sign Out
                                </MenuItem>
                            </Menu>
                            :
                            <Menu
                                anchorEl={loginEl}
                                id="login-menu"
                                className={styles.login_menu_list}
                                open={openLoginLayer}
                                onClose={handleLoginClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        borderRadius: '12px',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 20,
                                            width: 15,
                                            height: 15,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        }
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem selected={false} autoFocus={false} sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                    {openLoginLayer ? <LoginLayer layerMode={true} brand={brandName} loginCallback={loginCallback} handleDialogClose={handleLoginClose} />   : "loading..." }
                                </MenuItem>
                            </Menu>
                        }

                        </div>)
                    )
                }
            </div>
            {/*<div className={styles.marquee_container_bottom}>
                {headerBottomCmsData?.enabled && <ContentSlider content={headerBottomCmsData} />}
            </div>*/}
            </div>
                <>
                    <SwipeableDrawer
                        anchor="left"
                        open={openDrawer}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        sx={{
                            zIndex: '100000'
                        }}
                    >
                        <div>
                            <List>
                                <HeaderMenu menuJson={menuJson} deviceType="mobile" setOpenDrawer={setOpenDrawer} />
                                {
                                    lsv?.emailAddress &&
                                    <>
                                        <ListItem onClick={navigateToOrderHistory} sx={{ padding: '13px 26px' }}>
                                            ORDER HISTORY
                                        </ListItem>
                                        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.30)' }} />
                                        <ListItem onClick={signOutUser} sx={{ padding: '13px 26px' }}>
                                            <img src={signoutIcon.src} alt="Sign Out" style={{ paddingRight: '10px' }} /> Sign Out
                                        </ListItem>
                                    </>
                                }
                            </List>
                        </div>
                    </SwipeableDrawer>

                </>
            
            {brandName === 'gtr' && <div className={styles.header_space}></div>}
            {/*<Suspense>
                <ClearCart />
        </Suspense> */}
        </>
    )
}

export default HeaderComponent;

