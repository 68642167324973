import React, { useEffect, useState } from "react";
import Image from "next/image";
import cssStyles from "./contentSlider.module.scss";
import { trackItem } from "@/utils";
import { transformTextToHtml } from "@/components/cms_renderer";

interface ContentItem {
  type: "image" | "text";
  src?: string;
  content?: string;
  styles?: React.CSSProperties;
  link?: string;
  trackId?: string;
}

interface UICompType {
  method?: string;
  options?: {
    animInterval?: number;
    animEffect?: "fadeIn" | "fadeOut" | "scrollLeft" | "scrollRight" | "scrollUp" | "scrollDown";
  };
}

interface ContentSlider {
  trackId?: string;
  uiCompType?: UICompType;
  items: ContentItem[];
}

interface ContentSliderProps {
  content: ContentSlider;
}

const ContentSlider: React.FC<ContentSliderProps> = ({content}) => {
  const { uiCompType, items } = content;
  const [currentIndex, setCurrentIndex] = useState(0);
  const interval = (uiCompType?.options?.animInterval || 3) * 1000;
  const animEffect = uiCompType?.options?.animEffect || "scrollLeft";

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);

    return () => clearInterval(timer);
  }, [items.length, interval]);

  const handleClick = (itemTrackId: string) => {
    trackItem({
      id: itemTrackId,
      event: itemTrackId + 'marqeeClick'
    });
  };

  return (
    <div className={`${cssStyles.slider} ${cssStyles[animEffect]}`}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`${cssStyles.slide} ${index === currentIndex ? cssStyles.active : ""}`}
          style={item.styles}
        >
          {item.link ? (
            <a
              href={item.link}
              target="_blank"
              //rel="noopener noreferrer"
              onClick={() => item.trackId && handleClick(item.trackId)}
            >
              {item.type === "image" && item.src ? (
                <Image src={item.src} alt={`Slide ${index}`} layout="fill" objectFit="cover" />
              ) : (
                <div style={item.styles} className={cssStyles.text}>{transformTextToHtml(item.content || "")}</div>
              )}
            </a>
          ) : (
            <>
              {item.type === "image" && item.src ? (
                <Image src={item.src} alt={`Slide ${index}`} layout="fill" objectFit="cover" />
              ) : (
                <div style={item.styles} className={cssStyles.text} >{transformTextToHtml(item.content || "")}</div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContentSlider;