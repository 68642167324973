import { useEffect } from "react";
import styles from "./nesterror.module.scss";
import { gtmPageOnloadTracking } from "@/utils";

export interface ErrorProps {
    url?: string;
    method?: string;
    payload?: any;
    statusCode?: number;
    response?: any;
    message?: string;
    stackTrace?: string;
}

export const prepareErrorObj = (error: any) => {
    return {
        url: error.config.url,
        method: error.config.method,
        payload: error.config.data,
        statusCode: error.response && error.response.status ? error.response.status : 500,
        response: error.response ? error.response.data : 'No response',
    }
}


export function prepareErrorMessage(pageProps: any) {
    const { error, statusCode } = pageProps;
    let errorObj = JSON.parse(error) || {};
    let errorMsg: any = { "message": errorObj.message, "stack": errorObj.stack };
    try {
        let errorMsgStr = JSON.stringify(error, null, 2);
        if (errorMsgStr === "{}") {
            errorMsg = JSON.stringify({ "message": error.toString() });
        } else {
            errorMsg = errorMsgStr;
        }
    } catch (e) {
        errorMsg = JSON.stringify(error, null, 2);
    };
    console.error(errorMsg);
    return errorMsg;
}

export function prepareErrorProps(error: any) {
    let errorMsg: ErrorProps = {
        message: error.message,
        stackTrace: error.stack
    }
    return {
        props: {
            error: errorMsg,
            title: "Error | Nestingale",
            dataLayer: {
                pageType: "Error Page",
                pageName: "Error"
            }
        },
        revalidate: 1
    }
}


const NestError: React.FC = (props: any) => {
    const env = process.env.NEXT_PUBLIC_APP_ENV === 'qa' ? 'qa' : 'prod';
    const { error } = props;

    useEffect(() => {
        document.title = "Error | Nestingale";
        const pagePath = window.location.href.substring(0, window.location.href.lastIndexOf('&error=')) || window.location.href;
        gtmPageOnloadTracking({
            pageType: "Error Page",
            pageName: "Error",
            brandName: "Nestingale",
            status: error?.statusCode,
            pagePath: pagePath
        });
        console.error("Error : ", pagePath, error);
    }, []); 

    return (
        <div>
            <div className={styles["error-container"]}>
                <h2>Oops! Something went wrong! </h2>
                {error.message && <div> {error.message}</div>}
                {env != "prod" && <div>
                    {error.statusCode && <div><strong>Status Code:</strong> {error.statusCode}</div>}
                    {error.url && <div><strong>URL:  </strong> {error.url}</div>}
                    {error.method && <div><strong>Method:  </strong> {error.method}</div>}
                    {error.payload && <div><strong>Request Payload:  </strong> {JSON.stringify(error.payload)}</div>}
                    {error.response && <div><strong>Response:  </strong> {JSON.stringify(error.response)}</div>}
                    {error.stackTrace && <div><strong>Stack Trace:  </strong> {error.stackTrace}</div>}
                </div>}
            </div>
        </div>
    );
};

export default NestError;