import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider, Grid, List, ListItem, IconButton } from "@mui/material";
import qrCode from "../../assets/images/app_navigation_qr_code_live.png";
import dynamic from 'next/dynamic';
import { getUserInfo } from "@/utils/useUserInfo";
import { getUserId, isLoggedInUser, setUserInfo } from "@/utils/builder/userUtils";
import { fetchRoomModels } from "@/store/builder/thunks/roomModelsThunk";
import BuilderSelect from "@/store/builderStoreSelector";
import { RoomModel } from "@/store/builderSlice";
import { gtmClickTracking } from "@/utils";
import mwebPopup from '../../assets/images/mweb_popup.png';
import CloseIcon from '@mui/icons-material/Close';
import appleDownload from '../../assets/images/apple_app_download.png';

export interface ViewInMyRoomButtonProps {
    forceVisible?: boolean,
    buttonLabel?: string,
    showDivider?: boolean
}

const ViewInMyRoomButton: React.FC<ViewInMyRoomButtonProps> = ({ forceVisible, buttonLabel, showDivider = true }) => {
    const dispatch = useDispatch();
    const urlParams: { [key: string]: string } = BuilderSelect.ui().urlParams;
    const currentRoomId: string = BuilderSelect.activeSelection().roomId;
    const roomModels: RoomModel[] = BuilderSelect.roomModels().models;
    const currentRoomModel: RoomModel | undefined = roomModels.find(roomModel => roomModel.roomId === currentRoomId);
    const LoginLayer = dynamic(() => import('@/components/login'), {
        ssr: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [showLoginLayer, setShowLoginLayer] = useState(false);
    const isRoomUpdated = useRef(false);
    const showViewInMyRoom = useRef(currentRoomModel && currentRoomModel.cloned === 1);
    const isPromptDownload = urlParams.promptDownload === 'true';
    const redirectPageUrl: boolean = BuilderSelect.ui().redirectPageUrl;

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        gtmClickTracking({ event: 'builder_view_in_my_room.app_QRcode_viewed' });
    };

    const handleClose = () => {
        setAnchorEl(null);
        gtmClickTracking({ event: 'builder_view_in_my_room.app_popup_dismissed' });
    };

    const getLatestRoomModels = () => {
        dispatch(fetchRoomModels({
            customerId: getUserId(),
            isCreateRoomFlow: false,
            showcase: false,
            roomType: urlParams.roomType as string,
            updateCurrentRoomId: !isPromptDownload
        }) as any);
        showViewInMyRoom.current = false;
        handleClose();
    }

    const loginCallback = (data: any) => {
        if (isRoomUpdated.current) return;
        isRoomUpdated.current = true;
        getUserInfo("", true).then((userInfo) => {
            if (userInfo) {
                setUserInfo(userInfo);
                getLatestRoomModels();
            }
        });
    };

    const loginOrGetLatestRoomModels = () => {
        if (isLoggedInUser()) {
            getLatestRoomModels();
        } else {
            setShowLoginLayer(true);
        }
        gtmClickTracking({ event: '2Dbuilder_downloadapp.appQRcode_viewed' });
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const tooltipCss = {
        position: "relative",
        mt: "37px",
        "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 20,
            height: 20,
            top: -10,
            transform: "rotate(45deg)",
            left: "calc(50% - 75px)"
        }
    }
    useEffect(() => {
        if (isPromptDownload && buttonRef.current)
            buttonRef.current.click();
    }, [isPromptDownload]);

    useEffect(() => {
        if (redirectPageUrl) {
            const origin =
                typeof window !== 'undefined' && window.location.origin
                    ? window.location.origin
                    : '';
            const redirectUrl = `${origin}${redirectPageUrl}`;
            window.location.href = redirectUrl;
        }

    }, [redirectPageUrl]);

    const blurBckg = isPromptDownload ? { backgroundColor: 'grey', opacity: '0.9' } : {};

    if ((!showViewInMyRoom.current && !forceVisible) || urlParams.showcase == undefined)
        return <></>

    return (
        <>
            <Grid item>
                <Box component={'div'}
                    sx={{
                        paddingRight: '5px'
                    }}>
                    {buttonLabel || isPromptDownload ? (
                        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                            <img src={appleDownload.src} style={{ width: '110px' }} />
                        </div>
                    ) : (
                        <Button
                            id="view_in_my_room_clicked"
                            variant="contained"
                            color="secondary"
                            onClick={handleClick}
                            size="small"
                            style={{ textTransform: 'none' }}
                            ref={buttonRef}
                        >
                            {buttonLabel || isPromptDownload ? 'Download app' : 'View in my room'}
                        </Button>
                    )}

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: "white",
                                boxShadow: "none",
                                borderRadius: '8px'
                            }
                        }}
                        style={blurBckg}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 4,
                                top: 4,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {showLoginLayer ?
                            (<LoginLayer layerMode={true} brand={'brandName'} loginCallback={loginCallback} />) :
                            (<>
                                <Box component={'div'} sx={tooltipCss} />
                                <Box component={'div'}
                                    sx={{
                                        width: '350px',
                                        height: '560px',
                                        bgcolor: 'background.paper',
                                        borderRadius: '5px',
                                        margin: '0px',
                                        padding: '20px'
                                    }}>
                                    <Typography variant="h2" component="div"
                                        style={{ paddingBottom: '15px', fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
                                        You can visualize your own room using the Nestingale app! Download the app by scanning the QR code below:
                                    </Typography>
                                    <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
                                        <img src={qrCode.src} width={120} />
                                    </div>
                                    <div>
                                        <img src={mwebPopup.src} alt="" style={{ maxWidth: '95%', margin: 'auto', display: 'flex' }} />
                                    </div>
                                    <Divider flexItem sx={{ borderRightWidth: 2 }} />
                                    <Typography gutterBottom variant="h2" component="div"
                                        style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '6px', fontWeight: 'bold' }}>
                                        Click below after scanning your room
                                    </Typography>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            id="2Dbuilder_downloadapp.appQRcode_viewed"
                                            variant="contained"
                                            color="secondary"
                                            onClick={loginOrGetLatestRoomModels}
                                            size="small"
                                            style={{ textTransform: 'none' }}
                                        >
                                            View My Virtual Room Plan
                                        </Button>
                                    </div>

                                </Box>
                            </>)
                        }

                    </Popover>
                </Box>
            </Grid >
            {showDivider && <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 1, marginLeft: 1, marginRight: 1 }} style={{ background: 'black' }} />}
        </>
    );
}

export default ViewInMyRoomButton;