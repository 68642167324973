import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Image from 'next/image';
import { trackItem } from "@/utils";
import cssStyles from "./cms_renderer.module.scss";
import ReactMarkdown from 'react-markdown'
import { prepareCardsForCategoriesGrid } from '@/translator';
//TODO : need to refactor the existing product_cards.tsx to replace below shop_product_cards.tsx
import generateProductCards from "@/components/products/shop_product_cards";
import CommonCarouselComponent from "@/components/carousal/common_carousel";
import GenerateCategoryCard from "@/components/products/category_cards";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getProductsByIds } from "@/store/productSlice";


const CMS_HOST = process.env.CMS_HOST;

export const transformTextToHtml = (text: string, panelClassName?: string, id?: string, style?: React.CSSProperties) => {

    if (!panelClassName) {
        panelClassName = "sectionDescription";
    }
    function transformMarkdown(text: string, classNameStr: string, id?: string, style?: React.CSSProperties) {
        return <ReactMarkdown
            components={{
                p: ({ node, ...props }) => <p key={id || 'description'} className={cssStyles[classNameStr]} style={{ margin: "2px", ...style }} {...props} />
            }}
        >
            {text}
        </ReactMarkdown>;
    }
    const styleMatch = text.match(/<STYLE=({.*?})>(.*?)<\/STYLE>/);
    if (styleMatch) {
        const styleObject = JSON.parse(styleMatch[1].replace(/'/g, '"'));
        return transformMarkdown(styleMatch[2], panelClassName, id, { ...style, ...styleObject });
    }
    if (text.startsWith('<LARGE>')) {
        return transformMarkdown(text.replace('<LARGE>', '').replace('</LARGE>', ''), panelClassName + "LargeText", id, style)
    } else if (text.startsWith('<CENTER>')) {
        return transformMarkdown(text.replace('<CENTER>', '').replace('</CENTER>', ''), panelClassName + "CenterText", id, style);
    } else if (text.startsWith('<BOLD>')) {
        return transformMarkdown(text.replace('<BOLD>', '').replace('</BOLD>', ''), panelClassName + "BoldText", id, style);
    } else if (text === 'ELINE') {
        return <div key={id}>&nbsp;</div>;
    } else {
        return transformMarkdown(text, '', id, style);
    }
}

interface SectionData {
    trackId: string;
    title: string;
    description: string | string[];
    imageUrl?: string;
    backgroundImageUrl?: string;
    buttons?: {
        id: string;
        title: string;
        link: string;
        newTab: boolean;
        trackInfo: any;
    }[];
    pannels?: any[];
    items?: any[] | any;
    uiCompType?: {
        options?: {
            parasCount?: number;
            minHeight?: number;
        };
        styles?: {
            [key: string]: string | number | {};
        }
    };
}

export function MarkdownTextPanel({ sectionData }: { sectionData: SectionData }): JSX.Element | null {
    const { trackId, items} = sectionData;
    const {parasCount, minHeight } = sectionData.uiCompType?.options || {};
    const styles = sectionData.uiCompType?.styles || {};

    const panelClassName = trackId ? trackId + "_sectionDescription" : "sectionDescription";
    const [showMore, setShowMore] = React.useState(false);

    if (typeof items === "object") {
        if (minHeight) {
            return (
                <ExpandableContent maxHeight={minHeight}>
                    {items.map((descItem: string, index: number) => (
                        transformTextToHtml(descItem, panelClassName, trackId + index, styles)
                    ))}
                </ExpandableContent>
            );
        } else if (parasCount) {
            const showMoreParaCount = isNaN(parasCount) ? 1 : parasCount;
            const firstPart = items.slice(0, showMoreParaCount);
            const secondPart = items.slice(showMoreParaCount);

            return (
                <>
                    <div style={{...styles}}>
                        {firstPart.map((descItem: string, index: number) => {
                            let transformedText = transformTextToHtml(descItem, panelClassName, trackId + index);
                            if (!showMore && index + 1 === firstPart.length) {
                                // below transformation to include more/less is removing the parent <p> tag along with styles, so tried below, but still not working 
                                // because the children array is not mutable, so we can't push more elements to it
                                // 
                                // const transformedTextElement = transformedText as React.ReactElement;
                                // const childrenArray = React.Children.toArray(transformedTextElement.props.children);
                                // childrenArray.push(
                                //     <a key={`showMore-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(true); }}
                                //         style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                //         ...More
                                //     </a>
                                // );
                                // transformedText = transformedTextElement;

                                transformedText = React.createElement(
                                    React.Fragment,
                                    {},
                                    (transformedText as React.ReactElement).props.children,
                                    <a key={`showMore-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(true); }}
                                        style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                        ...More
                                    </a>
                                );
                            }
                            return transformedText;
                        })}
                    </div>
                    {showMore && (
                        <div style={{...styles}}>
                            {secondPart.map((descItem: string, index: number) => {
                                let transformedText = transformTextToHtml(descItem, panelClassName, trackId + showMoreParaCount + index);
                                if (index + 1 === secondPart.length) {
                                    transformedText = React.createElement(
                                        React.Fragment,
                                        {},
                                        (transformedText as React.ReactElement).props.children,
                                        <a key={`showLess-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(false); }}
                                            style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                            ...Less
                                        </a>
                                    );
                                }
                                return transformedText;
                            })}
                        </div>
                    )}
                </>
            );
        } else {
            return (
                <>
                    {items.map((descItem: string, index: number) => (
                        transformTextToHtml(descItem, panelClassName, trackId + index, styles)
                    ))}
                </>
            );
        }
    } else {
        return transformTextToHtml(items, panelClassName, trackId, styles);
    }
}

export function transformDescription(description: any, panelClassName?: string, trackId?: any): React.ReactNode {
    if (typeof description === "object") {
        return description.map((descItem: string, index: number) => (
            transformTextToHtml(descItem, panelClassName, trackId + index)
        ))
    } else return transformTextToHtml(description, panelClassName, trackId)
}

export type RenderFunctionType = {
    [key: string]: (sectionData: any, props: any) => JSX.Element | null;
};

export const ExpandableContent: React.FC<{ maxHeight: number; children: React.ReactNode }> = ({ children, maxHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
        if (expanded && wrapperRef.current) {
            //wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [showExpanderLink, setShowExpanderLink] = useState(false);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (contentRef.current) {
            setShowExpanderLink(contentRef.current.scrollHeight > maxHeight);
        }
    }, [maxHeight, children]);

    return (
        <div style={{ border: '0px solid red' }} id="expanderWrapper" ref={wrapperRef}>
            <div
                id="expanderContent"
                ref={contentRef}
                style={{
                    maxHeight: expanded ? `${contentRef.current?.scrollHeight}px` : `${maxHeight}px`,
                    overflow: 'hidden',
                    //border: '1px solid green',
                    transition: 'max-height 0.5s ease-in-out',
                }}
            >
                {children}
            </div>
            {showExpanderLink && (
                <div
                    id="expanderLink"
                    style={{
                        textAlign: 'right',
                        //marginTop: '-10px',
                        //borderTop: '1px solid black',
                    }}
                >
                    <a
                        onClick={toggleExpand}
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                        }}
                    >
                        {expanded ? '...Less -' : '... More +'}
                    </a>
                </div>
            )}
        </div>
    );
};

export function HeroImage(sectionData: any): JSX.Element | null {
    return (
        <section className={cssStyles.heroSectionContainer}>
            <div className={cssStyles.heroSectionTextContainer}>
                <Typography variant="h1" component="h1" className={cssStyles.heroSectionText1}>
                    {sectionData.title}
                </Typography>
                <Typography variant="h2" component="h2" className={cssStyles.heroSectionText2}>
                    {transformDescription(sectionData.description, undefined, sectionData.trackId)}
                </Typography>
                <div className={cssStyles.heroSectionBottom}>
                    <Button id={sectionData.buttons[0].id} className={cssStyles.heroSectionButton} color="primary" href={sectionData.buttons[0].link}
                        target={sectionData.buttons[0].newTab ? "_blank" : "_self"}
                        onClick={() => { trackItem(sectionData.buttons[0].trackInfo) }}
                    >
                        {sectionData.buttons[0].title}
                    </Button>
                </div>
            </div>
            <div className={cssStyles.heroSectionMedia}>
                <Image alt={sectionData.title} src={sectionData.backgroundImageUrl} layout="fill" objectFit="cover" />
            </div>
        </section>
    );
}

export function ImageButton(item: any, index: number = 1): JSX.Element | null {
    const baseClassName = "imgButtonCircular";
    return (
        <div key={item.trackId + index} className={cssStyles[baseClassName]}>
            <div
                className={cssStyles[`${baseClassName}__outerCircle`]}
                onClick={(e) => { trackItem(item.trackInfo); }}
            >
                <div
                    className={cssStyles[`${baseClassName}__innerCircle`]}
                    style={{
                        height: item.uiCompType?.options?.height || 200,
                        width: item.uiCompType?.options?.width || 200,
                    }}
                >
                    <div
                        className={cssStyles[`${baseClassName}__circle`]}
                        style={{ backgroundImage: `url(${item.imageUrl})` }}
                    >
                    </div>
                </div>
            </div>

            <Typography variant="h2" className={cssStyles[`${baseClassName}Text`]}>
                {item.title}
            </Typography>
        </div>
    );
}

export function ImageButtonWithLink(item: any, index: number = 1): JSX.Element | null {
    return item.link
        ? <a key={item.trackId + index} href={item.link}
            target={item.newTab ? "_blank" : "_self"}
            onClick={() => { trackItem(item.trackInfo) }}
        >
            {ImageButton(item, index)}
        </a>
        : ImageButton(item, index);
}

export function ImageButtons(sectionData: any): JSX.Element | null {
    const panelClassName = "imgButtons";
    const items = sectionData.items || sectionData.buttons;
    return (
        <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
            <div className={cssStyles[panelClassName + "Container"]}>
                {items?.map((item: any, index: number) => (
                    item.link ? ImageButtonWithLink(item, index) : ImageButton(item, index)
                ))}
            </div>
        </section>
    );
}

export function Panel(sectionData: any): JSX.Element | null {
    const orderClass = sectionData.uiCompType?.options?.imgPosition == "right" ? cssStyles.swapOrder : '';
    const panelClassName = "panel";
    const hasTextToShow = sectionData.title || sectionData.description || sectionData.buttons;

    return (
        <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
            <Grid container spacing={2} className={`${cssStyles[panelClassName + "Container"]} ${orderClass}`}>
                {sectionData.imageUrl &&
                    <Grid item xs={12} md={hasTextToShow ? 6 : 12} className={cssStyles[panelClassName + "ImageGrid"]}>
                        <div className={cssStyles[panelClassName + "Image"]}>
                            <Image alt={sectionData.title} src={sectionData.imageUrl} layout="fill" objectFit="cover" />
                        </div>
                    </Grid>
                }
                {hasTextToShow &&
                    <Grid item xs={12} md={sectionData.imageUrl ? 6 : 12} className={cssStyles[panelClassName + "TextGrid"]}>
                        <Box component="div" className={cssStyles[panelClassName + "Text"]}>

                            {sectionData.title && <Box component="h3" className={cssStyles[panelClassName + "Title"]}>
                                {sectionData.title}
                            </Box>}

                            {sectionData.description && <Box component="div" className={cssStyles[panelClassName + "Description"]}>
                                {transformDescription(sectionData.description, undefined, sectionData.trackId)}
                            </Box>}

                            {sectionData.buttons && sectionData.buttons.length > 0 &&
                                <span className={cssStyles[panelClassName + "Button"]}>
                                    <Button id={sectionData.trackId} color="secondary" href={sectionData.buttons[0].link}
                                        target={sectionData.buttons[0].newTab ? "_blank" : "_self"}
                                        onClick={() => { trackItem(sectionData.buttons[0].trackInfo) }}
                                    >{sectionData.buttons[0].title}</Button>
                                </span>
                            }
                        </Box>
                    </Grid>
                }
            </Grid>
        </section>
    );
}

export function GridPanel(sectionData: any): JSX.Element | null {
    const panelClassName = "gridPanel";
    return (
        <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>

            {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                {sectionData.title}
            </Typography>}

            <Grid container spacing={2} className={`${cssStyles[panelClassName + "GridContainer"]}`}>
                {sectionData.items?.map((item: any, index: number) => (
                    <Grid item xs={12} md={12 / sectionData.items.length} key={sectionData.trackId + index}>
                        <div className={cssStyles[panelClassName + "GridItem"]}>
                            {item.imageUrl &&
                                <div className={cssStyles[panelClassName + "Image"]}>
                                    <Image alt={item.title} src={item.imageUrl} layout="fill" objectFit="cover" />
                                </div>
                            }
                            <Box component="div" className={cssStyles[panelClassName + "Text"]}>
                                {item.title && (
                                    <Box component="h3" className={cssStyles[panelClassName + "Title"]}>
                                        {item.title}
                                    </Box>
                                )}
                                {item.description && transformDescription(item.description, undefined, item.trackId)
                                }
                            </Box>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </section>
    );
}

export function ImageButtonsWithPanel(sectionData: any): JSX.Element | null {
    const panelClassName = "imageButtonsWithPanel";
    return (
        <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
            {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                {sectionData.title}
            </Typography>}
            {sectionData.description && transformDescription(sectionData.description, undefined, sectionData.trackId)}
            {sectionData.buttons && sectionData.buttons.length > 0 &&
                ImageButtons(sectionData)
            }
            {sectionData.pannels && sectionData.pannels.length > 0 &&
                <div className={cssStyles[panelClassName + "Panels"]}>
                    {sectionData.pannels?.map((item: any, index: number) => (
                        Panel(item)
                    ))}
                </div>
            }
        </section>
    );
}

export function Carousel(sectionData: any, props: any): JSX.Element | null {
    const { vendorName } = props;
    const brandPathEntry = vendorName && vendorName !== "nestingale" ? `/${vendorName}` : "";
    const handleProductSelection = (cardIndex: any, event?: any, card?: any, sectionName?: string) => {
        console.log('Selected cardIndex: ' + cardIndex + "cardId : " + JSON.stringify(card) + "sectionName : " + sectionName);
        trackItem({
            id: card.id,
            event: sectionName + 'Click',
            name: card.name,
            section: sectionName
        });
    }

    const panelClassName = "carousel";
    return (
        <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
            {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                {sectionData.title}
            </Typography>}
            <Grid container className={cssStyles[panelClassName + "Container"]}>
                <CommonCarouselComponent fullWidth={true} type="scrollable"
                    cards={GenerateCategoryCard(
                        prepareCardsForCategoriesGrid(sectionData.items, brandPathEntry, vendorName),
                        handleProductSelection,
                        (sectionData.uiCompType?.options?.height || "320"),
                        (sectionData.uiCompType?.options?.width || "320"),
                        sectionData.trackId
                    )
                        .map((productCard: any) => {
                            return (<Grid key={productCard.name} item>{productCard}</Grid>);
                        })} />
            </Grid>
        </section>
    );
}

export function Accordion(sectionData: any): JSX.Element | null {
    const panelClassName = "accordion";

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
            {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                {sectionData.title}
            </Typography>}
            {sectionData.description && <Typography variant="body1" className={cssStyles[panelClassName + "Description"]}>
                {transformDescription(sectionData.description, "", sectionData.trackId)}
            </Typography>}
            <div className={cssStyles[panelClassName + "Container"]}>
                {sectionData.items && sectionData.items.map((item: any, index: number) => (
                    <MuiAccordion key={index} expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}
                        className={cssStyles[panelClassName + "AccordionItem"]}>
                        <AccordionSummary
                            expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            className={cssStyles["AccordionSummary"]}
                            sx={{
                                minHeight: 'initial !important',
                                '.Mui-expanded': {
                                    margin: '10px 0 !important'
                                }
                            }}
                        >
                            <strong>{item.title}</strong>
                        </AccordionSummary>
                        <AccordionDetails className={cssStyles["AccordionDetails"]}>
                            {transformDescription(item.description, undefined, item.trackId)}
                        </AccordionDetails>
                    </MuiAccordion>
                ))}
            </div>
        </section>
    );
}

export const renderFunctions: RenderFunctionType = {
    HeroImage,
    Panel,
    GridPanel,
    ImageButton,
    ImageButtonWithLink,
    ImageButtons,
    ImageButtonsWithPanel,
    Carousel,
    Accordion,
    MarkdownTextPanel
};

const generateProductCardsData2 = (data: any, vendorName: string) => {

    return data?.products?.map((product: any) => {
        const brandPathEntry = vendorName && vendorName !== 'nestingale' ? `/${vendorName}` : "";
        let pdpUrl = brandPathEntry + `/product/${product.productId}`;
        let roomTypeParam = product.roomType ? '&roomType=' + product.roomType : '';
        let productTypeParam = product.productType ? '&productType=' + product.productType : '';
        let viewInYourRoomLink = `/builder?productId=${product.productId}&showcase=true&auth=false${roomTypeParam}${productTypeParam}`;
        return {
            name: product.productName,
            id: product.productId,
            image: product.productImage,
            model3dUrl: product.model3dUrl,
            pdpUrl: pdpUrl,
            viewMoreUrl: "",
            price: product.price,
            comparePrice: product.comparePrice,
            viewInYourRoomLink: viewInYourRoomLink,
            otherSizes: product.otherSizes,
            otherColors: product.otherColors,
            priceRange: product.priceRange,
            expiresOn: product.expiresOn ? `Ends : ${new Date(product.expiresOn).toLocaleDateString()}` : 'While supplies last.',
            isBestSeller: product.isBestSeller
        };
    });
}

export const getCmsData = async (vendorName: string, cmsRelUrl: string, env: string) => {
    let cmsData: {
        title: string;
        description: string; 
        sections?: { [s: string]: any; };
        list?: Array<any>;
    } = {
        sections: {},
        list: [],
        title: "",
        description: ""
    };
    try {
        let cmsDataPromise = await fetch(`https://d2ffb7z1ailwvo.cloudfront.net/${cmsRelUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        cmsData = await cmsDataPromise.json();

        type PreprocessorsType = {
            [key: string]: (productIds: string[]) => Promise<any>;
        };

        const preprocessors: PreprocessorsType = {
            getProductDetails: async (productIds: string[]) => {
                let productsDataPromise = await getProductsByIds({ productIds: productIds, env: env, brand: vendorName });
                console.log('getProductDetails: productsDataPromise', productsDataPromise);
                return productsDataPromise;
            }
        };

        const processSections = async () => {
            try {
                const sectionPromises = Object.values(cmsData.sections || {}).map(async (section: any) => {
                    try {
                        if (section.preprocess && section.preprocess.method && section.preprocess.input) {
                            const data = await preprocessors[section.preprocess.method](section.preprocess.input);
                            console.log('Processed data:', data);
                            const processedData = generateProductCardsData2(data, vendorName);
                            section.items = processedData;
                        }
                    } catch (sectionError) {
                        console.error(`Error processing section ${section.name}:`, sectionError);
                    }
                });

                await Promise.all(sectionPromises);
                console.log('All sections processed');
                return cmsData;
            } catch (error) {
                console.error('Error processing sections:', error);
                return null;
            }
        };

        await processSections().then((result) => {
            if (result) {
                console.log('Processing completed successfully');
            } else {
                console.log('Processing failed');
            }
        }).catch((error) => {
            console.error('Unexpected error:', error);
        });
        return cmsData;

    } catch (e) {
        console.error("Error in fetching cms data", e);
    }
}


const CmsRenderer = (props: any) => {
    const { vendorName, vendorHomePage, env, vendorsApiData, pageCmsData, sectionCmsData, sectionCmsKey } = props;

    const renderSection = (sectionKey: string, sectionData: any, props: any) => {
        sectionData = sectionData || pageCmsData.sections[sectionKey];
        if (!sectionData) {
            console.error(`No section found for key: ${sectionKey}`);
            return null;
        }
        let renderFunction: string = sectionData.uiCompType?.method?.replace(/^render/, '');
        if (renderFunction && renderFunction in renderFunctions) {
            return renderFunctions[renderFunction](sectionData, props);
        } else {
            console.error(`No render function found for method: ${renderFunction}`);
            return null;
        }
    };

    return (
        <>
            {sectionCmsData && sectionCmsKey
                ? <React.Fragment key={sectionCmsKey}>
                    {renderSection(sectionCmsKey, sectionCmsData, props)}
                </React.Fragment>

                : pageCmsData.order?.map((secKey: string) => (
                    <React.Fragment key={secKey}>
                        {renderSection(secKey, pageCmsData.sections[secKey], props)}
                    </React.Fragment>
                ))}
        </>
    )
}

export default CmsRenderer;
