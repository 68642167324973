import styles from './footer.module.scss';
import { Box, Grid } from "@mui/material";
import companyIcon from "@/assets/images/company.svg";
import customerSupportIcon from "@/assets/images/customer_support.svg";
import shippinInfoIcon from "@/assets/images/truck.svg";
import instagramIcon from "@/assets/images/instagram_2.png";
import facebookIcon from "@/assets/images/facebook.png";
import pinterestIcon from "@/assets/images/pinterest.png";
import tiktokIcon from "@/assets/images/tiktok.png";
import youtubeIcon from "@/assets/images/youtube.png";
import heartIcon from "@/assets/images/heart.svg";

const FooterComponent = (props) => {
    const { brand } = props;
    return (
        <>
            <div className={styles.socialMediaSection}>
                <div>
                    <span className={styles.socialMediaSectionFollowus}>
                        <img src={heartIcon.src} alt="Follow us icon" />
                        Follow us
                    </span>
                    <a href="https://www.instagram.com/nestingale/" id="instagram_icon" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon.src} alt="Instagram icon" width="40px" />
                    </a>
                    <a href="https://www.facebook.com/nestingale" id="facebook_icon" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon.src} alt="Facebook icon" />
                    </a>
                    <a href="https://www.pinterest.com/nestingale/" id="pinterest_icon" target="_blank" rel="noopener noreferrer">
                        <img src={pinterestIcon.src} alt="Pinterest icon" />
                    </a>
                    <a href="https://www.tiktok.com/@nestingale.com" id="tiktok_icon" target="_blank" rel="noopener noreferrer">
                        <img src={tiktokIcon.src} alt="TikTok icon" />
                    </a>
                    <a href="https://www.youtube.com/@nestingale" id="youtube_icon" target="_blank" rel="noopener noreferrer">
                        <img src={youtubeIcon.src} alt="YouTube icon" />
                    </a>
                </div>
            </div>
        <div className='footersection'>
            <div className='footer_container'>
                <div id={styles["footer-a"]}>
                    <div className={styles["foot-nav-main"]}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <div className={styles["foot-nav"]}>
                                    <h4><img src={companyIcon.src} alt="Company Icon" /> Company</h4>
                                    <a href="/pages/about-us">About us</a>
                                    <a href="/blogs/ideas">Blog</a>
                                    <a href="/pages/how-it-works">How it works</a>
                                    <a href="/pages/brand-product-partners">Brand Partners</a>
                                    <a href="#">Press inquiries</a>
                                    <a href="#">Partner inquiries</a>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className={styles["foot-nav"]}>
                                    <h4><img src={customerSupportIcon.src} alt="Customer Support Icon" /> Customer Support</h4>
                                    <a href="/pages/how-it-works">FAQs</a>
                                    <a href="#">Help Center</a>
                                    <a href="/pages/contact">Contact us </a>
                                    <a href="/pages/return-policy">Return policy</a>
                                    <a href="/pages/accessibility">Accessibility</a>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className={styles["foot-nav"]}>
                                    <h4><img src={shippinInfoIcon.src} alt="Shipping Icon" /> SHIPPING INFO</h4>
                                    <a href="/pages/shipping-policy">Shipping Policy</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={styles["foot-bottom"]}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className={styles.foot_bottom_links}>
                                    <a href="/pages/terms-of-use">Terms of Use</a>
                                    <a href="/pages/privacy-policy">Privacy Policy</a>
                                    <a href="/pages/cookie-policy">Cookie Settings</a>
                                </div>
                                <Box component={'span'} className={styles["copyright"]}>©2023 Nestingale | All Rights Reserved</Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FooterComponent;